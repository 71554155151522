import React from 'react';

const Yakinda = () => {
    return (
        <div className="yakinda-container">
            <div className="code-elements">
                <div className="code-line">&lt;div class="future"&gt;</div>
                <div className="code-line">while (true) {`{`}</div>
                <div className="code-line">  await future.load();</div>
                <div className="code-line">  if (ready) break;</div>
                <div className="code-line">{`}`}</div>
                <div className="code-line">&lt;/div&gt;</div>
                <div className="binary">010011 100101 110010</div>
                <div className="binary">101010 001111 110101</div>
            </div>
            
            <div className="text-container">
                <h1>&lt;Çok/&gt;</h1>
                <h1>Yakında</h1>
            </div>

            <style jsx>{`
                .yakinda-container {
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #0a192f;
                    position: relative;
                    overflow: hidden;
                }

                .code-elements {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                    opacity: 0.15;
                }

                .code-line {
                    position: absolute;
                    color: #64ffda;
                    font-family: 'Consolas', monospace;
                    font-size: 1.2rem;
                    animation: slideCode 15s linear infinite;
                    white-space: nowrap;
                }

                .binary {
                    position: absolute;
                    color: #8892b0;
                    font-family: 'Consolas', monospace;
                    opacity: 0.5;
                    animation: fadeBinary 8s linear infinite;
                }

                .text-container {
                    text-align: center;
                    z-index: 10;
                    animation: glitch 5s ease-in-out infinite;
                }

                h1 {
                    font-size: 5rem;
                    font-weight: 600;
                    letter-spacing: 0.5rem;
                    color: #64ffda;
                    margin: 0;
                    text-shadow: 0 0 10px rgba(100, 255, 218, 0.3);
                    font-family: 'Consolas', monospace;
                }

                h1:first-child {
                    margin-bottom: 1rem;
                    color: #ccd6f6;
                }

                .code-line:nth-child(1) { top: 10%; left: 5%; }
                .code-line:nth-child(2) { top: 25%; right: 10%; }
                .code-line:nth-child(3) { top: 45%; left: 15%; }
                .code-line:nth-child(4) { top: 65%; right: 15%; }
                .code-line:nth-child(5) { top: 85%; left: 20%; }
                .code-line:nth-child(6) { bottom: 10%; right: 25%; }

                .binary:nth-child(7) { top: 35%; left: 40%; }
                .binary:nth-child(8) { bottom: 30%; right: 35%; }

                @keyframes slideCode {
                    0% {
                        transform: translateX(-100%);
                        opacity: 0;
                    }
                    10% {
                        opacity: 1;
                    }
                    90% {
                        opacity: 1;
                    }
                    100% {
                        transform: translateX(100%);
                        opacity: 0;
                    }
                }

                @keyframes fadeBinary {
                    0%, 100% { opacity: 0.2; }
                    50% { opacity: 0.5; }
                }

                @keyframes glitch {
                    0% {
                        transform: translate(0);
                    }
                    1% {
                        transform: translate(-2px, 2px);
                    }
                    2% {
                        transform: translate(2px, -2px);
                    }
                    3% {
                        transform: translate(0);
                    }
                    95% {
                        transform: translate(0);
                    }
                    96% {
                        transform: translate(-2px, 2px);
                    }
                    97% {
                        transform: translate(2px, -2px);
                    }
                    100% {
                        transform: translate(0);
                    }
                }

                @media (max-width: 768px) {
                    h1 {
                        font-size: 3rem;
                    }
                    .code-line {
                        font-size: 1rem;
                    }
                }
            `}</style>
        </div>
    );
};

export default Yakinda;
