import Test from './pages/Test';
import Yakinda from './pages/Yakinda';



const publicRoutes = [
    { name: "Test", path: "/test", element: <Test /> },
    { name: "AnaSayfa", path: "/", element: <Yakinda /> },
    { name: "AnaSayfa", path: "/*", element: <Yakinda /> },
]

export { publicRoutes } 
